<template>
  <div class="verify-pwd-contaner p32 b">
    <p class="title">修改登录密码</p>
    <div class="form-box">
      <el-form
        label-width="130px"
        :model="ruleForm"
        :status-icon="false"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
      >
        <!-- 请输入旧密码 -->
        <el-form-item label="请输入旧密码:" required prop="oldPwd">
          <el-input
            type="password"
            maxlength="20"
            v-model="ruleForm.oldPwd"
            placeholder="请输入旧密码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 请输入旧密码结束 -->
        <!-- 请输入新密码 -->
        <el-form-item label="请输入新密码:" required prop="newPwd">
          <el-input
            type="password"
            maxlength="20"
            v-model="ruleForm.newPwd"
            placeholder="设置登录密码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 请输入新密码结束 -->
        <!-- 请再次输入新密码： -->
        <el-form-item label="请再次输入新密码:" required prop="aginNewPwd">
          <el-input
            type="password"
            maxlength="20"
            v-model="ruleForm.aginNewPwd"
            placeholder="确认登录密码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 请再次输入新密码：结束 -->
        <!-- 提交开始 -->
        <el-form-item>
          <el-button class="btn cf f16" @click="submitForm('ruleForm')"
            >确认修改</el-button
          >
        </el-form-item>
        <!-- 提交结束 -->
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "YoufujichuangVerifyPwd",

  data() {
    var validateOldPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入旧密码"));
      }
      callback();
    };
    var validateNewPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } // else {
      //   if (
      //     !/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(
      //       this.ruleForm.newPwd
      //     )
      //   ) {
      //     callback(new Error("密码包含数字和字母，且在6-18位之间"));
      //   }
      // }
      callback();
    };
    var validateAginNewPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        oldPwd: "",
        newPwd: "",
        aginNewPwd: "",
      },
      rules: {
        oldPwd: [{ validator: validateOldPwd, trigger: "blur" }],
        newPwd: [{ validator: validateNewPwd, trigger: "blur" }],
        aginNewPwd: [{ validator: validateAginNewPwd, trigger: "blur" }],
      },
    };
  },

  mounted() {},

  methods: {
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let paramData = {
            oldPwd: this.ruleForm.oldPwd,
            newPwd: this.ruleForm.newPwd,
            confirmNewPwd: this.ruleForm.aginNewPwd,
            type: 1, //旧密码验证
          };
          this.$API
            .changePwd(paramData)
            .then((res) => {
              if (res.code == 1) {
                this.$alert("密码修改成功,请重新登录", "提示", {
                  confirmButtonText: "确定",
                  // cancelButtonText: "取消",
                  // showCancelButton: true,
                  callback: (action) => {
                    // 确定  confirm  取消 cancel
                    if (action == "confirm") {
                      // this.$message({
                      //   message: "删除成功",
                      //   type: "success",
                      // });
                      this.$store.commit("clearInfo");
                      this.$router.push("/login");
                    }
                  },
                });
              } else {
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });

          // alert("submit!");
          //   成功跳转
          // this.$router.push("/contact/set/revise-comp");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.verify-pwd-contaner {
  padding: 32px;
  .title {
    padding-bottom: 16px;
    border-bottom: 1px solid #eeeeee;
    font-size: 22px;
    font-weight: bold;
    color: #333333;
  }
  // 表单区域
  .form-box {
    margin-top: 32px;
    margin-left: 28px;
    // 性別
    .item {
      display: inline-block;
      margin-left: 20px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .item2 {
      margin-left: 56px;
    }
    //input表单样式
    .el-input__inner {
      width: 320px;
      height: 40px;
    }
    //input下边距
    .el-form-item {
      margin-bottom: 32px;
    }
    //label样式
    .el-form-item__label {
      text-align: right;
    }
    //多行文本样式
    .el-textarea__inner {
      width: 400px;
      height: 120px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
    }
    //按钮样式
    .btn {
      width: 214px;
      height: 50px;
      background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
      border-radius: 8px 8px 8px 8px;
      color: #ffffff;
      margin-top: 316px;
      margin-left: 203px;
    }
  }
}
</style>